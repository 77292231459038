import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import { Row, Col, Container } from "react-bootstrap"

export default () => <Layout>
	<Helmet title="Gina McDonald &amp; Associates" defer={false} />

	<Container className="my-5">
		<Row>
			<Col md={6}>
				<h2>Contact us today</h2>
				<ContactForm />
			</Col>
			<Col md={6}>
				<img className="img-fluid" src="corinne-kutz-tMI2_-r5Nfo-unsplash.jpg" />
				{/* todo - import image */}
			</Col>
		</Row>
	</Container>
</Layout>